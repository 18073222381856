import React, { useContext, useState, useEffect } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import classnames from 'classnames';
import App from '../../App';
import {useNavigate} from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import { IDS_Downloads, IDS_DownloadUserManual, IDS_UserManual, IDS_UserManualVersion, IDS_DownloadWebAppUserGuide, IDS_Version, IDS_Download, IDS_WebAppUserGuide } from '../../VcLanguage';
import { FaArrowAltCircleLeft, FaBookOpen } from 'react-icons/fa';
import VcSideNav from './DeviceHome/VcSideNav';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { MdClose } from 'react-icons/md';
import { RiMenuUnfoldLine } from 'react-icons/ri';


const VcDownloads = () => {

    const context = useContext(AppRelevantDataContext);
    const navigate = useNavigate();
    
    const t = context.t;

    const [activeTab, setActiveTab] = useState('webAppUserManual');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if( context.loggedInUserInfo.userFirstName.length <= 0 ) {
          // User login information is not present in appRelevantDataContext.
          // Try to get this value if present in LocalStorage and update the app state (so that
          // re-render will happen). If app context data not present in LocalStorage then
          // show the Login Page
          if ( context.updateAppRelevantDataContextFromLocalStorage() === false ) {
            // App Relevant Data Context not present in LocalStorage as well.
            navigate('/', { replace: true });
          } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
          }
    
        }
    }, []);

    useEffect(() => {
    
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if( context.loggedInUserInfo.userFirstName.length <= 0 ) {
        // User login information is not present in appRelevantDataContext.

        // Next re-render will happen automatically when the AppState is updated again 
        // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
        return App.renderLoadingPage();
    }

    const renderWebAppUserManual = () => {
        return (
            <>
                <h3 className='p-1'>{t(IDS_Downloads)}</h3>
                <Nav tabs className="tabular">
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab == 'webAppUserManual' })}>
                            <b>{t(IDS_UserManual)}</b>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="webAppUserManual" className="tabular2">
                        <Row>
                            <Col sm="12">
                                <div className="form-group reg-form">
                                    <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                        <label className="reg-form-label" style={{fontSize: "0.9rem", paddingLeft: "0.4rem"}}>{t(IDS_WebAppUserGuide)} {t(IDS_Version)} - v2.0</label>
                                        <Button 
                                            type="text" 
                                            onClick={() => window.open('http://viliso.in/wp-content/uploads/2025/02/SmartHHMMWebAppUserGuide_V2.0.pdf', '_blank')} 
                                            style={{
                                                fontSize: "1rem", 
                                                color: "white", 
                                                fontWeight: "500", 
                                                padding: "0.4rem", 
                                                borderRadius: "0.5rem", 
                                                width: "100%", 
                                                marginTop: "0.2rem", 
                                                textTransform: "none", 
                                                backgroundColor: "var(--primaryColor)", 
                                                borderColor: "var(--primaryColor)"
                                            }}
                                        >
                                            {t(IDS_Download)} {t(IDS_WebAppUserGuide)} <FaBookOpen style={{ marginTop: "-0.1rem", fontSize: "1.2rem"}} />
                                        </Button>
                                    </div>
                                </div>
                            </Col> 
                        </Row>
                    </TabPane>
                </TabContent>   
            </>
        )
    }

    if(windowWidth < 570) {
       return  (
            <>  
                <div  className={`drawerDiv ${showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
                    <VcSideNav />
                    <FaArrowAltCircleLeft 
                        className="closingArrow top-nav" onClick={()=> setShowDrawer(false)}
                    />
                </div>
                <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{position:"sticky", top:"0", borderRadius:"0.75rem"}}>
                    <div className='' style={{width:"60px"}}>
                    <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
                    </div>
                    {showDrawer
                        ? <MdClose classname='transition-all' onClick={()=> setShowDrawer(false)}/> 
                        : <span onClick={()=> setShowDrawer(true)}>
                            <RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} />
                          </span> 
                    }
                </div>
                {renderWebAppUserManual()}   
            </>
        )
    }

    return (
        <div className='w-100 d-flex relative '>
            <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
                <VcSideNav/>
            </div>
            
            <div className="container col-lg-8 col-lg-offset-3 centered col-md-6 col-md-offset-3 p-1" style={{width:"55%",overflow: 'none'}}>
                <div className="modal-body box rounded-3" style = {{padding:"1rem"}}>
                    {renderWebAppUserManual()}                  
                </div>
            </div>
        </div>
    );
};

export default VcDownloads;
